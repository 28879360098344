@font-face {
  font-family: 'Satoshi';
  src: url('./Satoshi-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./Satoshi-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./Satoshi-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./Satoshi-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./Satoshi-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
